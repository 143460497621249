.section {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
}

.table {
    border-top: 3px solid #cccccc;
    font-size: .9em;
}

.row {
    display: flex;
    border-bottom: 1px solid #cccccc;
}

.guideText {
    color: rgba(0,0,0,0.54);
}

.label {
    color: rgba(0,0,0,0.54);
    padding: 6px 10px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.input {
    align-self: stretch;
}

.subButton {
    margin-top: 16px;
    font-size: 14px;
}

.labelText {
    color: rgba(0,0,0,0.54);
}

.content {
    padding: 6px 10px;
    flex: 1;
}

.inputRow {
    display: flex;
    margin-bottom: 4px;
}

.inputContent {
    margin: 0 8px;
    flex: 1;
}

.subButton {
    margin-top: 16px;
    font-size: 14px;
}