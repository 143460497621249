.section {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
}

.table {
    border-top: 3px solid #cccccc;
}

.row {
    display: flex;
    border-bottom: 1px solid #cccccc;
}

.header {
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.header:nth-child(1), .header:nth-child(2), .header:nth-child(3) {
    width: 160px;
    justify-content: flex-end;
    border-right: 1px solid #cccccc;
}


.label {
    background-color: #f0f0f0;
    padding: 8px 16px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-right: 1px solid #cccccc;
}

.sublabel {
    background-color: #fafafa;
    padding: 8px 16px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-right: 1px solid #cccccc;
}

.labelText {
    color: rgba(0,0,0,0.54);
}

.content {
    padding: 8px 16px;
    flex: 1;
    min-width: 140px;
}