.page {
    padding-bottom: 56px;
}

.section {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
}

.table {
    border-top: 3px solid #cccccc;
}

.row {
    display: flex;
    border-bottom: 1px solid #cccccc;
}

.guideText {
    color: rgba(0,0,0,0.54);
}

.label {
    background-color: #f0f0f0;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 160px;
}

.labelText {
    color: rgba(0,0,0,0.87),
}

.content {
    padding: 8px 16px;
    flex: 1;
}

.inputRow {
    display: flex;
    margin-bottom: 4px;
}

.inputContent {
    margin: 0 8px;
    flex: 1;
}

.subButton {
    margin-top: 16px;
    font-size: 14px;
}
