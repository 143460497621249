.h4 {
    margin-bottom: 24px;
}

.h5 {
    margin-bottom: 8px;
}

.subtitle1 {
    margin-bottom: 8px;
}

.subtitle2 {
    margin-bottom: 6px;
}

.body1 {
    margin-bottom: 4px;
}

.section {
    margin-top: 40px;
    margin-bottom: 40px;
}

.subsection {
    margin-top: 12px;
    margin-bottom: 24px;
}

.emphasize {
    font-weight: bold;
}