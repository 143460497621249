html {
  font-size: 14px;
}

.oneline-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.guideText {
  color: rgba(0,0,0,0.54);
}

/* table */
table {
  font-size: .8rem;
}

thead {
  background-color: #f9fbe7;
}

table th, table td {
  vertical-align: middle;
}

table th, table td {
  text-align: center;
}
/* end: table */


/* mui input */
.MuiInputBase-root input {
  font-size: .9rem;
}

.MuiInputBase-root .MuiSelect-select {
  font-size: .9rem;
}
/* end: mui input */

/* bootstrap input */
.form-control {
  border-radius: 0;
}
.input-group * {
  border-radius: 0;
}
.input-group-item {
  border: 1px solid rgb(206, 212, 218);
}

label.form-check-label {
  font-size: 0.875rem;
}
/* end: bootstrap input */

/* bootstrap dropdown */
.dropdown-menu .dropdown-item {
  font-size: .875rem;
}
/* end: bootstrap dropdown */